import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Collapse } from 'reactstrap';
import { navigate } from "gatsby";

const Header = (props) => {
  const [menuCollapses, setMenuCollapses] = useState({
    collapse1: false,
    collapse2: false,
    collapse3: false
  });
  const [navToggled, setNavToggled] = useState(false);
  const [navMobileToggled, setNavMobileToggled] = useState(false);
  const [city, setCity] = useState('');

  const preventBodyScroll = (shouldPrevent) => {
    const body = document.body;
    
    if (shouldPrevent) {
      // Save the current scroll position
      const scrollY = window.scrollY;
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.top = `-${scrollY}px`;
    } else {
      // Restore scroll position
      const scrollY = Math.abs(parseInt(body.style.top || '0'));
      body.style.position = '';
      body.style.width = '';
      body.style.top = '';
      window.scrollTo(0, scrollY);
    }
  };
  
  const toggleNav = () => {
    const nextNavState = !navToggled;
    preventBodyScroll(nextNavState);
    setNavToggled(nextNavState);
    setNavMobileToggled(false);
  };
  
  const toggleMobileNav = () => {
    const nextNavState = !navMobileToggled;
    preventBodyScroll(nextNavState);
    setNavMobileToggled(nextNavState);
    setNavToggled(false);
  };
  
  

  // const toggleNav = () => {
  //   const body = document.body;
  //   const nextNavState = !navToggled;

  //   if (nextNavState) {
  //     body.addEventListener('touchmove', preventScroll, { passive: false });
  //     body.style.overflow = 'hidden';
  //     document.documentElement.style.overflow = 'hidden';
  //   } else {
  //     body.removeEventListener('touchmove', preventScroll);
  //     body.style.overflow = '';
  //     document.documentElement.style.overflow = '';
  //   }
  //   setNavToggled(!navToggled);
  //   setNavMobileToggled(false);
  // };

  // const toggleMobileNav = () => {
  //   const body = document.body;
  //   const nextNavState = !navMobileToggled;

  //   if (nextNavState) {
  //     body.addEventListener('touchmove', preventScroll, { passive: false });
  //     document.body.style.overflow = 'hidden';
  //     document.documentElement.style.overflow = 'hidden';
  //   } else {
  //     body.removeEventListener('touchmove', preventScroll);
  //     document.body.style.overflow = '';
  //     document.documentElement.style.overflow = '';
  //   }
  //   setNavMobileToggled(!navMobileToggled);
  //   setNavToggled(false);
  // };

  const toggleMenuAccordion = (e) => {
    e.preventDefault();

    const updatedMenuCollapses = { ...menuCollapses };
    const collapse = e.currentTarget.id;

    for (var key in updatedMenuCollapses) {
      if (updatedMenuCollapses.hasOwnProperty(key) && collapse !== key) {
        updatedMenuCollapses[key] = false;
      }
    }

    updatedMenuCollapses[collapse] = !updatedMenuCollapses[collapse];
    setMenuCollapses(updatedMenuCollapses);
  };

  console.log(`state=${city}`);
  const { siteId, phase, multiLang, language, displayHeader, path } = props.data;
  let url;
  if (siteId === 'newcastleFanPerformance') {
    url = '/newcastle';
  } else {
    url = `/${siteId}`;
  }

  if (siteId === '') {
    return true;
  }

  if (multiLang) {
    url += `/${language}`;
  }

  if (!displayHeader)
    return false;
  else
    return (
      <React.Fragment>
        <header>
          {phase === 'open' &&
            <>
              <div className="burger-menu">
                <a className={`open-nav ${navMobileToggled ? 'is-open' : ''}`} href="#" onClick={toggleMobileNav}>
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </div>
            </>
          }

          <button className={`open-loc-nav ${phase !== 'open' && 'open'} ${navToggled && 'is-open'}`} onClick={toggleNav}>
            <span>Select Location</span>
            <span>Close</span>
          </button>

          <nav className={`navigation ${navMobileToggled ? 'is-open' : ''}`}>
            <ul className="navigation__primary-ul">
              {/* {siteId === 'zurich' &&
                <li><Link className={`language-link ${language === 'de' ? 'active' : ''}`}
                          to={`/${siteId}/de/`}>DE</Link> | <Link
                  className={`language-link en ${language === 'en' ? 'active' : ''}`}
                  to={`/${siteId}/en/`}>EN</Link></li>
              } */}
              {siteId === 'aarhus' &&
                <li
                onClick={toggleMobileNav}
                ><Link className={`language-link ${language === 'da' ? 'active' : ''}`}
                          to={`/${siteId}/da/`}>DA</Link> | <Link
                  className={`language-link en ${language === 'en' ? 'active' : ''}`}
                  to={`/${siteId}/en/`}>EN</Link></li>
              }
              {phase === "open" && siteId !== 'uk-eu' &&
                <li
                onClick={toggleMobileNav}
                ><Link activeClassName="active" to={`${url}/`}>Home</Link>
                </li>
              }
              {phase === "open" && siteId !== 'uk-eu'  &&
                <li
                onClick={toggleMobileNav}
                ><Link activeClassName="active" to={`${url}/show/`}>The&nbsp;Show</Link></li>
              }
              {phase === "open" && siteId !== 'uk-eu' &&
                <li
                onClick={toggleMobileNav}
                ><Link activeClassName="active" to={`${url}/press/`}>Press</Link></li>
              }
              {phase === "open" && siteId !== 'uk-eu' &&
                <li
                onClick={toggleMobileNav}
                ><Link activeClassName="active" to={`${url}/gallery/`}>Gallery</Link></li>
              }
              {phase === "open" &&
                <li
                onClick={toggleMobileNav}
                ><a target="_blank" href="https://www.bookofmormonshop.co.uk/">Shop</a></li>
              }
              {phase === "open" &&
                <>
                  {siteId === 'zurich' ?
                    <li><a rel="noreferrer noopener" target="_blank"
                           href="https://twitter.com/bookofmormoneu">@Bookofmormoneu</a></li>
                    :
                    <li><a rel="noreferrer noopener" target="_blank"
                           href="https://twitter.com/bookofmormonuk">@Bookofmormonuk</a></li>
                  }
                </>
              }
            </ul>
          </nav>
        </header>


        <div className={`loc-navigation ${navToggled ? 'is-open' : ''}`}>
          <p>SELECT LOCATION</p>

          <ul className="navigation__ul">
            {siteId !== 'london' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/london/">London</Link>
              </li>
            }
            {siteId !== 'bradford' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/bradford/">Bradford</Link>
              </li>
            }
            {siteId !== 'birmingham' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/birmingham/">Birmingham</Link>
              </li>
            }
            {siteId !== 'plymouth' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/plymouth/">plymouth</Link>
              </li>
            }
            {siteId !== 'hull' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/hull/">hull</Link>
              </li>
            }
            {siteId !== 'dublin' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/dublin/">Dublin</Link>
              </li>
            }
            {siteId !== 'nottingham' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/nottingham/">Nottingham</Link>
              </li>
            }
            {siteId !== 'cardiff' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/cardiff/">Cardiff</Link>
              </li>
            }
            {siteId !== 'newcastle' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/newcastle/">Newcastle</Link>
              </li>
            }
            {siteId !== 'norwich' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/norwich/">Norwich</Link>
              </li>
            }
            {siteId !== 'aberdeen' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/uk-eu/signup/?city=aberdeen">Aberdeen</Link>
              </li>
            }
            {siteId !== 'glasgow' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/glasgow/">Glasgow</Link>
              </li>
            }
            {siteId !== 'liverpool' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/uk-eu/signup/?city=liverpool">Liverpool</Link>
              </li>
            }
            {siteId !== 'zurich' &&
              <li
              onClick={toggleNav}
              >
                <Link to="/zurich/">Zurich</Link>
              </li>
            }
            {siteId !== 'milan' &&
              <li
              
              onClick={toggleNav}>
                <a href="https://thebookofmormonmusical.it/" target="_blank" rel="noopener">Milan</a>
              </li>
            }
            <li><a href="https://thebookofmormontour.com/">US Tour</a></li>
            <li><a href="https://bookofmormonbroadway.com/">Broadway</a></li>
            <li><a href="https://thebookofmormonmusical.com.au">Australia</a></li>
          </ul>
        </div>
      </React.Fragment>
    );
};

export default Header;
